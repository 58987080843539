import { CountryCodeList } from 'types/countries';
import configJSON from 'config.json';
import { formatNumberDown } from '@zoocasa/node-kit/numbers/format-number';

export interface AgentLicense {
  // expires: string;
  locale: string;
  number: string;
  state: string;
  primary: boolean;
}

export interface AgentLocation {
  city: string;
  state: string;
  zipcode: string;
}

export interface AgentFull {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  photo: string;
  preferredName: string;
  title: string;
  bio: string;
  languages: string[];
  specializations: string[];
  designations: string[];
  certifications: string[];
  facebook: string;
  instagram: string;
  linkedIn: string;
  twitter: string;
  website: string;
  youtube: string;
  tiktok: string;
  city: string;
  state: string;
  zipcode: string;
  countryCode: string;
  asab: string[];
  stateBroker: string[];
  activeLocations: AgentLocation[];
  license: AgentLicense[];
  isOffboarding: boolean;
}

export interface AgentList {
  id: string;
  firstName: string;
  lastName: string;
  city: string;
  state: string;
  photo: string;
  email: string;
  phoneNumber: string;
  bio: string;
}

interface LocationSuggestionsResponse {
  locationSuggestions: string[];
}

interface NameSuggestionsResponse {
  nameSuggestions: string[];
}

export interface AgentsResponseBody {
  count: number;
  agents: AgentList[];
}

interface SearchAgentsResponse {
  search: AgentsResponseBody;
}

interface FeaturedAgentsResponse {
  featuredAgents: AgentsResponseBody;
}

interface FullAgentResponse {
  agent: AgentFull;
}

interface AgentEndpointParams {
  query: string;
  headers?: Record<string, string> | null;
}

/**
 * Agent count response body
 * @param {number} total - total number of agents
 * @param {Object} countries - count of agents by country
 * @param {number} countries.ca - count of agents in Canada
 * @param {number} countries.us - count of agents in US
 */
export interface AgentCountBody {
  total: number;
  countries: {
    ca: number;
    us: number;
  };
  // brokers: number;
}

interface AgentCountResponse {
  agentCounts: AgentCountBody;
}

enum SearchType {
  AGENT = 'AGENT',
  BROKER = 'BROKER',
}

export enum AgentType {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
}

enum SortType {
  FirstName = 'firstName',
  LastName = 'lastName',
  Random = 'random',
}

enum SortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

enum TokenActionType {
  AGENT_SEARCH = 'AGENT_SEARCH',
  VIEW_AGENT = 'VIEW_AGENT',
}

export const PAGE_SIZE = 12;
export const AGENT_SEARCH_PATH = '/agents-search';
export const EXP_COMMERCIAL_PATH = '/experts';

/**
 *  Search agents by name, location and country
 *
 * @param name - Agent name query
 * @param location - Location query accepts state/province code like ON, BC, MI, etc
 * @param country - country code CA or US
 * @param page - page number
 * @param session_id - is a unique value to pass to ElasticSearch as a 'preference',
 * this ensures all subsequent requests get routed to the same shard. This is most important for paginated requests which
 * were sorting results differently across shards.
 * @returns
 *
 * Sort:
 *  Field name to sort by. Currently only firstName and lastName are 'officially' supported. Elasticsearch might allow other
 *  fields but they shouldn't be expected to always work. If you need a field to be sortable reach out to the middleware team.
 */

interface AgentSearchProps {
  name: string;
  location: string;
  country: string;
  page: number;
  session_id: string | null;
  agentType: AgentType;
}

const searchAgents = async ({ name, location, country, page, session_id, agentType = AgentType.RESIDENTIAL }: AgentSearchProps): Promise<AgentsResponseBody | Error> => {
  const token = await generateRecaptchaToken(TokenActionType.AGENT_SEARCH);
  if (!token) return { count: 0, agents: []};
  const isRandomCommercial = !name && !location && agentType === AgentType.COMMERCIAL;

  const query = `
    query {
      search(
        ${name ? `name: "${name}",` : ''}
        ${location ? `location: "${location}",` : ''}
        country: "${country}",
        sort: { name: "${isRandomCommercial ? SortType.Random : SortType.FirstName}", direction: ${SortDirection.ASCENDING} },
        pagination: { size: ${PAGE_SIZE}, from: ${(page - 1) * PAGE_SIZE} },
        searchType: ${SearchType.AGENT},
        agentType : ${agentType},
        recaptchaToken: "${token || ''}"
      ) {
        count
        agents {
          id
          firstName
          lastName
          city
          state
          photo
          email
          phoneNumber
          bio
        }
      }
    }
  `;

  const params = {
    query,
    headers: session_id ? { 'X-Search-ID': session_id } : null,
  };

  const response = await agentEndpoint(params) as SearchAgentsResponse | Error;
  if (response instanceof Error) {
    return {
      count: 0,
      agents: [],
    };
  }
  const { search } = response;
  return search;
};

/**
 * Get set or featured agents based on the country
 *
 * @param country - country code CA or US
 * @param searchType - search type AGENT or BROKER
 * @returns AgentsResponseBody
 */

const featuredAgents = async (country: CountryCodeList, searchType = SearchType.AGENT): Promise<AgentsResponseBody | Error> => {
  const featuredAgentsQuery = `
    query {
      featuredAgents(
        country: "${country}"
        searchType: ${searchType}
      ) {
        count
        agents {
          id
          firstName
          lastName
          city
          state
          photo
          bio
        }
      }
    }
  `;

  const params = {
    query: featuredAgentsQuery,
  };

  const response = await agentEndpoint(params) as FeaturedAgentsResponse | Error;
  if (response instanceof Error) {
    return {
      count: 0,
      agents: [],
    };
  }
  const { featuredAgents } = response;
  return featuredAgents;
};

/**
 * Fetches location suggestions from the agent directory API
 *
 * @param query - search query
 * @param country - country code CA or US
 * @returns
 */
const locationSuggestions = async (query: string, country: string): Promise<string[]> => {
  const locationSuggestionsQuery = `
  query {
    locationSuggestions(
      term: "${query}",
      country: "${country}"
    )
  }
`;

  const response = await agentEndpoint({ query: locationSuggestionsQuery }) as LocationSuggestionsResponse | Error;
  if (response instanceof Error) {
    console.error('Error fetching location suggestions', response);
    return [];
  }
  const { locationSuggestions } = response;
  return locationSuggestions || [];
};

/**
 * Fetch name suggestions from the agent directory API
 *
 * @param query - search query
 * @param country - country code CA or US
 * @param agentType - agent type RESIDENTIAL or COMMERCIAL
 * @returns
 */

const nameSuggestions = async (query: string, country: string, agentType = AgentType.RESIDENTIAL): Promise<string[] | Error> => {
  const nameSuggestionsQuery = `
    query {
      nameSuggestions(
        term: "${query}",
        country: "${country}",
        agentType: ${agentType}
      )
    }
  `;

  const response = await agentEndpoint({ query: nameSuggestionsQuery }) as NameSuggestionsResponse | Error;
  if (response instanceof Error) {
    console.error('Error fetching name suggestions', response);
    return [];
  }
  const { nameSuggestions } = response;
  return nameSuggestions;
};

/**
 * Fetch agent by id
 * Test agent ID - de1cb15c-471d-11ee-86df-f3cc451c09a0
 *
 * @param id Agent ID
 * @returns
 */
const fetchAgent = async (id: string): Promise<AgentFull | Error> => {
  const token = await generateRecaptchaToken(TokenActionType.VIEW_AGENT);
  const query = `
    query {
      agent(
        id: "${id}",
        recaptchaToken: "${token || ''}"
      ) {
        id
        firstName
        lastName
        email
        phoneNumber
        photo
        preferredName
        title
        bio
        languages
        specializations
        designations
        certifications
        facebook
        instagram
        linkedIn
        twitter
        website
        youtube
        tiktok
        city
        state
        zipcode
        countryCode
        asab
        stateBroker
        activeLocations {
          city
          state
          zipcode
        }
        license {
          locale
          number
          state
          primary
        }
        isOffboarding
      }
    }
  `;

  const response = await agentEndpoint({ query }) as FullAgentResponse | Error;
  if (response instanceof Error) {
    console.error('Error fetching agent', response);
    return response;
  }

  const { agent } = response;
  return agent;
};

/**
 * Fetch total number of agents as well as agents count by country
 * @returns AgentCountBody
 */
const agentCount = async () => {
  // Brokers ca be added to the params if needed in the future
  const query = `
    query {
      agentCounts() {
        total
        countries {
          ca
          us
        }
      }
    }
  `;

  const response = await agentEndpoint({ query }) as AgentCountResponse | Error;
  if (response instanceof Error) {
    console.error('Error fetching agent count', response);
    return { total: 0, countries: { ca: '0', us: '0' }};
  }

  const { total, countries } = response.agentCounts;
  return { total, countries: { ca: formatNumberDown(countries.ca), us: formatNumberDown(countries.us) }};
};

/**
 *
 * @param params
 * @returns
 */

const agentEndpoint = async (params: AgentEndpointParams): Promise<Response | Error> => {
  const { query, headers } = params;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
    body: JSON.stringify({ query }),
  };
  try {
    const response = await fetch(configJSON.agentSearchUrl, options);
    const data = await response.json();

    if (data.errors) {
      console.error('Error fetching agents', data);
      throw new Error(data.errors[0].message);
    }

    return data.data;
  } catch (error: any) {
    if (error.message === 'Failed to fetch') {
      return new Error('Error fetching agents');
    }

    if (error.message === 'Unauthorized') {
      return new Error('Unauthorized');
    }

    if (error.message === 'Forbidden') {
      return new Error('Forbidden');
    }

    return new Error('Error fetching agents');
  }
};

// In order to generate a recaptcha token, the recaptcha script must be loaded on the page that calls this function.
// Right now this is only implemented in agent search page /pages/agents/index.tsx
const generateRecaptchaToken = (action: TokenActionType): Promise<string | null> => {
  return new Promise(resolve => {
    if (window && window.grecaptcha) {
      window.grecaptcha.enterprise.ready(async () => {
        try {
          const token = await window.grecaptcha.enterprise.execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, { action });
          resolve(token);
        } catch (error) {
          console.error('Error generating recaptcha token: ', error);
          resolve(null);
        }
      });
    } else {
      console.error('Error generating recaptcha token: Library not loaded yet.');
      resolve(null);
    }
  });
};

export {
  searchAgents,
  locationSuggestions,
  nameSuggestions,
  featuredAgents,
  fetchAgent,
  agentCount,
};
